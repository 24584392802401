<template>
  <div :id="mapId" style="position: relative; height: 100%;"></div>
</template>

<script>
import MapBoxGL from 'mapbox-gl'
import hull from 'hull.js'
import {getCenterPoint} from "../../utils/calcCenterPoint";
import treeImg from '../../assets/img/map-tree.png'
// import * as turf from '@turf/turf'
import TreeType from "../../api/TreeType";

export default {
  name: 'MapBoxItemComponent',

  props: {
    areaData: {
      required: false,
      type: Object
    },
    mapId: {
      required: false,
      type: String,
      default: 'map'
    }
  },
  watch: {
    area: function () { // watch it
      this.createMap()
    },
  },
  data() {
    return {
      accessToken: 'pk.eyJ1IjoibWFnaW9kZXYiLCJhIjoiY2t3YzdjZ2ltMWZlczJ3cDJubXFheHl0bSJ9.B1spSbf4EfBOlDhYVmRZMQ',
      container: 'map', // container ID
      style: 'mapbox://styles/josedvd123/ckyubv4uz001v14lcg26hetvw', // style URL
      zoom: 15.5, // starting zoom
      map: {},
      allAreaTreesCoordinates: [],
    }
  },

  mounted() {
    this.container = this.mapId || 'map'
    this.createMap()
  },

  methods: {
    async createMap() {

      console.log(this.areaData, 'this.areaDataaaaaaaaaaaaa')

      if (this.areaData) {
        let allAreaTreesCoordinates = [];
        for (const treeType of this.areaData.treeTypes) {
          allAreaTreesCoordinates = allAreaTreesCoordinates
            .concat(
              treeType.trees
                .map(tree => [tree.lng, tree.lat])
            );
        }
        this.allAreaTreesCoordinates = allAreaTreesCoordinates;

        this.areaData.centerPoint = getCenterPoint(allAreaTreesCoordinates);

        MapBoxGL.accessToken = this.accessToken;

        this.map = new MapBoxGL.Map({
          container: this.container,
          style: this.style,
          center: this.areaData.centerPoint,
          zoom: this.zoom,
          attributionControl: false,
          preserveDrawingBuffer: true,
        });

        this.addLayers()
      }
    },
    addLayers() {
      this.map.on('render', () => {
        this.map.resize();
      });
      this.map.on('load', () => {
        this.centerPoints();
        this.map.loadImage(treeImg, async (error, image) => {
          if (error) throw error;

          this.map.addImage('tree-icon', image);

          console.log(this.areaData.treeTypes, 'TEST!!!!')
          let allAreaTreesCoordinates = []
          for (const treeType of this.areaData.treeTypes) {
            const areaTreeType = (await TreeType.getTreeTypes(treeType.id)).data;
            const ref = treeType.reference;

            allAreaTreesCoordinates = allAreaTreesCoordinates
              .concat(
                treeType.trees
                  .map(tree => [tree.lng, tree.lat])
              );


            let features = treeType.trees.map((tree) => {
              return {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [tree.lng, tree.lat]
                }
              };
            })


            this.map.addSource('forests-source' + ref, {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features
              }
            });

            this.map.addLayer({
              id: 'forests' + ref,
              source: 'forests-source' + ref,
              type: 'circle',
              paint: {
                'circle-color': areaTreeType?.color || '#42b883',
                'circle-radius': 2
              }
            });
          }

          let perimeterCoordinates = hull(
            allAreaTreesCoordinates,
            2)

          this.map.addSource('perimeter-source', {
            'type': 'geojson',
            'data': {
              'type': 'Feature',
              'geometry': {
                'type': 'Polygon',
                'coordinates': [
                  perimeterCoordinates
                ]
              }
            }
          });


          // this.map.addLayer({
          //   'id': 'perimeter',
          //   'type': 'fill',
          //   'source': 'perimeter-source', // reference the data source
          //   'layout': {},
          //   minzoom: 11,
          //   maxzoom: 13,
          //   'paint': {
          //     'fill-color': '#42b883', // blue color fill
          //     'fill-opacity': 0.2
          //   }
          // });

          // this.map.addLayer({
          //   'id': 'outline',
          //   'type': 'line',
          //   'source': 'perimeter-source',
          //   'layout': {},
          //   minzoom: 11,
          //   maxzoom: 13,
          //   'paint': {
          //     'line-color': '#0a7045',
          //     'line-width': 3
          //   }
          // });


          // const coordinatesArea = +(turf.area({
          //   'type': 'Feature',
          //   'geometry': {
          //     'type': 'Polygon',
          //     'coordinates': [
          //       perimeterCoordinates
          //     ]
          //   }
          // }) / 10000).toFixed(2);


          // this.map.addSource('area-source', {
          //   'type': 'geojson',
          //   'data': {
          //     'type': 'FeatureCollection',
          //     'features': [
          //       {
          //         'type': 'Feature',
          //         'geometry': {
          //           'type': 'Point',
          //           'coordinates': this.areaData.centerPoint
          //         },
          //         "properties": {
          //           "area": coordinatesArea,
          //           "trees": allAreaTreesCoordinates.length
          //
          //         }
          //       }
          //     ]
          //   }
          // });


          // this.map.addLayer({
          //   "id": "area-units",
          //   "type": "symbol",
          //   minzoom: 11,
          //   maxzoom: 13,
          //   "source": "area-source",
          //   "layout": {
          //     "symbol-placement": "point",
          //     "text-font": ["Open Sans Bold"],
          //     "text-field": '{trees} trees\n{area} ha', // part 2 of this is how to do it
          //     "text-size": 12,
          //   },
          //   'paint': {
          //     'text-color': '#0a7045'
          //   },
          // });

          // this.map.addLayer({
          //   'id': 'area',
          //   'type': 'symbol',
          //   maxzoom: 11,
          //   'source': 'area-source', // reference the data source
          //   'layout': {
          //     'icon-image': 'tree-icon', // reference the image
          //     'icon-size': 0.2
          //   }
          // });


          for (const i in this.areaData.perimeters) {
            let geometry = this.areaData.perimeters[i];

            this.map.addSource(`perimeter-src${i}`, {
              'type': 'geojson',
              'data': {
                'type': 'Feature',
                geometry
              }
            })

            this.map.addLayer({
              'id': `perimeter-line${i}`,
              'type': 'line',
              'source': `perimeter-src${i}`,
              minzoom: 11,
              'layout': {},
              'paint': {
                'line-color': '#000',
                'line-width': 2
              }
            });
          }

        });
      });
    },

    centerPoints() {
      let bounds = new MapBoxGL.LngLatBounds();
      this.allAreaTreesCoordinates.forEach(coord => {
        bounds.extend(coord);
      });

      this.map.fitBounds(bounds, {
        padding: {top: 12, bottom: 12, left: 12, right: 12},
      });
    }
  }
}
</script>

<style scoped>

</style>
