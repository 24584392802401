import {all, create} from 'mathjs';
import moment from "moment";
import TreeType from "@/api/TreeType";

/**
 *
 * @param treeTypes
 * @param datetime
 * @returns {{datetime, treeTypes: {}, CO2: number, qty: number, theoreticalStats: *[]}}
 */
const renderTreeTypeData = async (treeTypes, datetime) => {
  const math = create(all)
  let data = {
    datetime,
    CO2: 0,
    CO2Comparison: 0,
    qty: 0,
    theoreticalStats: [],
    treeTypes: {}
  }
  // console.log({treeTypes})
  for (let treeType of treeTypes) {

    treeType.CO2 = 0;
    treeType.CO2Comparison = 0;
    treeType.heightAverage = 0;
    treeType.diameterAverage = 0;
    treeType.theoreticalStats = [];
    treeType.qty = treeType.trees?.length;

    // let biggestHeight = 0;
    // let biggestTree = {};
    let treeTypeData = (await TreeType.getTreeTypes(treeType.id)).data;
    let practicalFormulas = treeTypeData.practical_formulas;
    let theoreticalAdditionalityFormulas = treeTypeData.theoretical_additionality_formulas;
    for (const tree of treeType.trees) {
      // if (biggestHeight < tree.height) {
      //   biggestTree = {
      //     diameter: tree.diameter,
      //     height: tree.height,
      //   }
      // }

      if (typeof treeType.practicalFormulas == 'string') {
        treeType.practicalFormulas = JSON.parse(treeType.practicalFormulas);
      }

      for (const practicalFormulaData of practicalFormulas) {

        const practicalFormula = math.parse(practicalFormulaData.formula).compile();

        treeType.CO2 += tree.diameter && tree.height ? parseFloat(practicalFormula.evaluate({
          density_kg: treeType.density_kg,
          diameter: tree.diameter,
          height: tree.height,
        })) : 0;


        if (practicalFormulaData.theoreticalComparison) {
          treeType.CO2Comparison += tree.diameter && tree.height ? parseFloat(practicalFormula.evaluate({
            density_kg: treeType.density_kg,
            diameter: tree.diameter,
            height: tree.height,
          })) : 0;
        }
      }

      treeType.heightAverage += tree.height / treeType.qty;
      treeType.diameterAverage += tree.diameter / treeType.qty;
    }


    const theoreticalFormula = math.parse(treeType.theoreticalFormula).compile();

    // let labels = [];
    //   let data = [];
    //   let dataAdditional = [];
    //   let dataAdditionalOptional = {};

    let totalTonCCO2 = 0;
    let totalTonCCO2Additional = 0;
    // let totalAdditionalityTonCCO2 = 0;
    for (let i = 1; i <= 150; i++) {
      const tonCCO2PerTree = theoreticalFormula.evaluate({
        density_kg: treeType.density_kg,
        year: i,
      })
      let tonCCO2Additional = {};

      let dataAdditionalOptional = {};
      let tonCCO2AdditionalPerTree = 0;
      for (const j in theoreticalAdditionalityFormulas) {
        const data = theoreticalAdditionalityFormulas[j];

        if (data.formula) {
          const theoreticalAdditionalityFormula = math.parse(data.formula).compile()

          const theoreticalAdditionalityValue = theoreticalAdditionalityFormula.evaluate({
            density_kg: treeType.density_kg,
            base: tonCCO2PerTree,
            year: i,
          }) || 0;

          let key = data.name || (parseInt(j) + 1)

          if (!dataAdditionalOptional[key]) {
            dataAdditionalOptional[key] = []
          }


          tonCCO2AdditionalPerTree += theoreticalAdditionalityValue;

          dataAdditionalOptional[key].push(
            theoreticalAdditionalityValue.toFixed(5)
          )
          if (!tonCCO2Additional[key]) tonCCO2Additional[key] = 0;
          tonCCO2Additional[key] += theoreticalAdditionalityValue * treeType.trees.length;
          // tonCCO2Additional[key].push(theoreticalAdditionalityValue);
          // if(!tonCCO2Additional[key][i-1]) tonCCO2Additional[key][i-1] = 0;
          //   tonCCO2Additional[key][i-1] += theoreticalAdditionalityValue;
        }
      }
//         labels.push(i + ' Years');
//         data.push(tonCCO2PerTree.toFixed(5));
//         dataAdditional.push(tonCCO2AdditionalRequiredPerTree.toFixed(5));


      let subTotalTonCCO2 = 0;
      subTotalTonCCO2 += (tonCCO2PerTree < 0 ? 0 : tonCCO2PerTree) * treeType.trees.length;

      const oldTotalTonCCO2 = totalTonCCO2;
      totalTonCCO2 = subTotalTonCCO2;

      const theoreticalIncrement = totalTonCCO2 - oldTotalTonCCO2;

      let subTotalTonCCO2Additional = 0;
      subTotalTonCCO2Additional += (tonCCO2AdditionalPerTree < 0 ? 0 : tonCCO2AdditionalPerTree) * treeType.trees.length;

      const oldTotalTonCCO2Additional = totalTonCCO2Additional;
      totalTonCCO2Additional = subTotalTonCCO2Additional;

      const theoreticalIncrementAdditional = totalTonCCO2Additional - oldTotalTonCCO2Additional;

      treeType.theoreticalStats.push({
        label: i + ' Years',
        index: treeType.theoreticalStats.length - 1,
        totalTonCCO2,
        theoreticalIncrement,
        totalTonCCO2Additional,
        theoreticalIncrementAdditional,
        tonCCO2Additional
        // totalAdditionalityTonCCO2: tonCCO2AdditionalRequiredPerTree,
        // totalAdditionalityTonCCO2Increment: tonCCO2AdditionalRequiredPerTreeIncrement,
      });
    }
  }
  for (let i = 0; i < 150; i++) {
    data.theoreticalStats.push({
      index: i,
      totalTonCCO2: 0,
      theoreticalIncrement: 0,
      totalTonCCO2Additional: 0,
      theoreticalIncrementAdditional: 0,
      tonCCO2Additional: {}
    });
  }

  for (const treeType of treeTypes) {
    data.qty += treeType.qty;
    data.CO2 += treeType.CO2;
    data.CO2Comparison += treeType.CO2Comparison;

    for (let i = 0; i < 150; i++) {
      data.theoreticalStats[i].totalTonCCO2 += treeType.theoreticalStats[i].totalTonCCO2;
      data.theoreticalStats[i].theoreticalIncrement += treeType.theoreticalStats[i].theoreticalIncrement;
      data.theoreticalStats[i].totalTonCCO2Additional += treeType.theoreticalStats[i].totalTonCCO2Additional;
      data.theoreticalStats[i].theoreticalIncrementAdditional += treeType.theoreticalStats[i].theoreticalIncrementAdditional;
      for (const key in treeType.theoreticalStats[i].tonCCO2Additional) {
        if (!data.theoreticalStats[i].tonCCO2Additional[key]) data.theoreticalStats[i].tonCCO2Additional[key] = 0;
        data.theoreticalStats[i].tonCCO2Additional[key] += treeType.theoreticalStats[i].tonCCO2Additional[key];
      }
    }
  }

  let yearFound = false;
  for (let i = 0; i < 150; i++) {

    // console.log((data.theoreticalStats[i].totalTonCCO2 >= data.CO2 && !yearFound) + ' | data.theoreticalStats[i].totalTonCCO2 ' + data.theoreticalStats[i].totalTonCCO2 + ' >= ' + data.CO2 + ' data.CO2 && !yearFound ' + !yearFound)
    // console.log((data.theoreticalStats[i].totalTonCCO2 >= data.CO2Comparison && !yearFound) + ' | data.theoreticalStats[i].totalTonCCO2 ' + data.theoreticalStats[i].totalTonCCO2 + ' >= ' + data.CO2Comparison + ' data.CO2Comparison && !yearFound ' + !yearFound)

    if (data.theoreticalStats[i].totalTonCCO2 >= data.CO2Comparison && !yearFound) {
      data.theoreticalStats[i].checkpointYear = true;
      yearFound = true;
    } else {
      data.theoreticalStats[i].checkpointYear = false;
    }
  }


  let count = 0;
  for (const i in data.theoreticalStats) {
    const stat = data.theoreticalStats[i];
    if (stat.checkpointYear || count > 0) {
      stat.year = parseInt(moment(data.datetime).format('YYYY')) + count;
      count++;
    }
  }

  count = 0;
  for (const i in data.theoreticalStats.reverse()) {
    const stat = data.theoreticalStats[i];
    if (stat.checkpointYear || count > 0) {
      stat.year = parseInt(moment(data.datetime).format('YYYY')) - count;
      count++;
    }
  }

  data.theoreticalStats.reverse();
  data.treeTypes = treeTypes;

  return data;
}

export {renderTreeTypeData}
