import Ajax from './Ajax.js'

const Checkpoint = {
  getCheckpoints: (id = null) => {
    let uri = id !== null
      ? '/checkpoints' + '/' + id
      : '/checkpoints'
    return Ajax.get(uri)
  },
  postCheckpoints: (params) => {
    return Ajax.post('/checkpoints', params)
  },
  putCheckpoints: (params) => {
    return Ajax.put('/checkpoints/' + params.id, params)
  },
  delCheckpoints: (id) => {
    return Ajax.delete('/checkpoints/' + id)
  },
  postCheckpointTreeTypes: (params) => {
    return Ajax.post('/checkpoint-tree-types/bulk', params)
  },
  postCheckpointTreeTypesFertilizing: (params) => {
    return Ajax.post('/checkpoint-tree-types/'+params.checkpoint_tree_type_id+'/fertilizing', params)
  },
  postTheoreticalStats: (params) => {
    return Ajax.post('/theoretical-stats/bulk', params)
  },

}

export default Checkpoint
